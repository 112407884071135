import { Box, MenuItem } from '@qonsoll/react-design'

import styled from 'styled-components'

// props here were intentionally placed in lowercase, to avoid console error related to the dom element attribute
const StyledMenuItem = styled(MenuItem)`
  margin-right: 8px;
  box-shadow: none !important;
  height: auto !important;
  cursor: default;
  color: ${({ textcolor }) => textcolor || 'var(--ql-menu-item-color)'};
  &:hover {
    background-color: transparent !important;
    color: ${({ textcoloractive }) =>
      `${textcoloractive || 'var(--ql-menu-item-color-hover)'} !important`};
  }

  /* Check if 'textcoloractive' prop is passed and update the variable */
  ${({ textcoloractive }) =>
    textcoloractive &&
    `
      --ql-menu-horizontal-main-item-color-hover: ${textcoloractive};
    `}
`
const StyledMenuWrapper = styled(Box)`
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ bg }) => bg || 'var(--ql-header-preview-menu-bg)'};
  width: 100%;
`
export { StyledMenuItem, StyledMenuWrapper }
