import {
  AnyPageConfigurationSimpleForm,
  CustomBodyPublicWizard,
  GeneralConfigurationSimpleForm,
  PublicWizardConfiguration,
  SettingsSimpleForm,
  WebTippsPagePreview,
  WidgetConfigurationSimpleForm,
  WidgetPreview
} from 'domains/WebTippsConfiguration/components'
import { Col, Row } from '@qonsoll/react-design'
import { useMemo, useState } from 'react'

import { useWebTippsConfigurationBaseActions } from 'domains/WebTippsConfiguration/hooks'

const useGetWebTippsConfigurationTabComponents = (
  initialValues,
  webTippsConfigurationId
) => {
  const [pageConfiguration, setPageConfiguration] = useState(null)
  const [widgetConfiguration, setWidgetConfiguration] = useState(null)
  const [publicWizardConfiguration, setPublicWizardConfiguration] =
    useState(null)

  const { onUpdate } = useWebTippsConfigurationBaseActions({
    webTippsConfigurationId,
    initialValues
  })

  const getSettingsInitialValues = (dataAll) => ({
    app: {
      defaultLanguage: dataAll?.app?.defaultLanguage,
      newspaperFrontpageAddress: dataAll?.app?.newspaperFrontpageAddress || null
    }
  })
  const getGeneralConfigurationsInitialValues = (dataAll) => ({
    app: {
      logoUrl: dataAll?.app?.logoUrl,
      backgroundColor: dataAll?.app?.backgroundColor || '#FFFFFF'
      // pageTitleColor: dataAll?.app?.pageTitleColor
    },
    header: {
      backgroundColor: dataAll?.header?.backgroundColor || '#FFFFFF',
      textColor: dataAll?.header?.textColor,
      avatarBackgroundColor: dataAll?.header?.avatarBackgroundColor,
      avatarTextColor: dataAll?.header?.avatarTextColor
    },
    menu: {
      backgroundColor: dataAll?.menu?.backgroundColor || '#FFFFFF',
      textColor: dataAll?.menu?.textColor,
      textColorActive: dataAll?.menu?.textColorActive
    },
    footer: {
      backgroundColor: dataAll?.footer?.backgroundColor || '#FFFFFF',
      textColor: dataAll?.footer?.textColor,
      mainText: dataAll?.footer?.mainText
    }
  })
  const getAnyPageConfigurationsInitialValues = (dataAll, entityName) => ({
    [entityName]: {
      title: dataAll?.[entityName]?.title,
      description: dataAll?.[entityName]?.description
    }
  })
  const handleWebTippsConfigurationsFormSubmit = (data) => {
    onUpdate(data)
  }

  // Form initial values for widget config for the preview to work properly
  const widgetConfigInitialValues = useMemo(() => {
    const button = initialValues?.widgetConfig?.button

    return {
      ...initialValues?.widgetConfig,
      button: {
        ...button,
        redirectTo: button?.redirectTo || initialValues?.domainName || ''
      }
    }
  }, [initialValues])

  return useMemo(
    () => [
      {
        name: 'Design',
        component: (
          <Row noOuterGutters>
            <Col cw={6}>
              <GeneralConfigurationSimpleForm
                pageConfiguration={pageConfiguration}
                setPageConfiguration={setPageConfiguration}
                onSubmit={handleWebTippsConfigurationsFormSubmit}
                initialValues={getGeneralConfigurationsInitialValues(
                  initialValues
                )}
              />
            </Col>
            <Col cw={6}>
              <WebTippsPagePreview
                initialValues={initialValues}
                pageConfiguration={pageConfiguration}
              />
            </Col>
          </Row>
        ),
        cw: 12
      },
      {
        name: 'Settings',
        component: (
          <Row noOuterGutters>
            <Col>
              <SettingsSimpleForm
                pageConfiguration={pageConfiguration}
                setPageConfiguration={setPageConfiguration}
                onSubmit={handleWebTippsConfigurationsFormSubmit}
                initialValues={getSettingsInitialValues(initialValues)}
              />
            </Col>
          </Row>
        ),
        cw: 12
      },
      {
        name: 'Login page',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['login']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'login'
            )}
          />
        )
      },
      {
        name: 'Registration page',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['registration']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'registration'
            )}
          />
        )
      },
      {
        name: 'SMS confirmation page',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['smsConfirmation']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'smsConfirmation'
            )}
          />
        )
      },
      {
        name: 'Dashboard',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['dashboard']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'dashboard'
            )}
          />
        )
      },
      {
        name: 'Send tip page',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['sendTip']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'sendTip'
            )}
          />
        )
      },
      {
        name: 'After submitting tip page',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['successSubmission']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'successSubmission'
            )}
          />
        )
      },
      {
        name: 'Rewards list page',
        component: (
          <AnyPageConfigurationSimpleForm
            onSubmit={handleWebTippsConfigurationsFormSubmit}
            group={['rewardsList']}
            initialValues={getAnyPageConfigurationsInitialValues(
              initialValues,
              'rewardsList'
            )}
          />
        )
      },
      {
        name: 'Widget configurations',
        component: (
          <Row noOuterGutters>
            <Col cw={[12, 12, 6, 6, 6, 5]}>
              <WidgetConfigurationSimpleForm
                onSubmit={handleWebTippsConfigurationsFormSubmit}
                initialValues={{
                  ...initialValues,
                  widgetConfig: widgetConfigInitialValues
                }}
                group={['widgetConfig']}
                setWidgetConfiguration={setWidgetConfiguration}
              />
            </Col>
            <Col cw={[12, 12, 6, 6, 6, 7]}>
              <WidgetPreview
                initialValues={initialValues}
                pageConfiguration={pageConfiguration}
                widgetConfiguration={{
                  ...widgetConfigInitialValues,
                  ...widgetConfiguration
                }}
              />
            </Col>
          </Row>
        ),
        cw: 12
      },
      {
        name: 'Public wizard configurations',
        component: (
          <Row noOuterGutters>
            <Col cw={[12, 12, 6, 6, 6, 5]}>
              <PublicWizardConfiguration
                onSubmit={handleWebTippsConfigurationsFormSubmit}
                initialValues={{
                  ...initialValues
                }}
                group={['publicWizardConfiguration']}
                setPublicWizardConfiguration={setPublicWizardConfiguration}
              />
            </Col>
            <Col cw={[12, 12, 6, 6, 6, 7]}>
              <WebTippsPagePreview
                isWizard
                initialValues={initialValues}
                pageConfiguration={pageConfiguration}
                publicWizardConfiguration={
                  publicWizardConfiguration?.publicWizardConfiguration
                }
                customBodyContent={
                  <CustomBodyPublicWizard
                    initialValues={getAnyPageConfigurationsInitialValues(
                      initialValues,
                      'sendTip'
                    )}
                    publicWizardConfiguration={
                      publicWizardConfiguration?.publicWizardConfiguration
                    }
                  />
                }
                customTitle={'Tip wizard'}
              />
            </Col>
          </Row>
        ),
        cw: 12
      }
    ],
    [
      initialValues,
      pageConfiguration,
      widgetConfiguration,
      publicWizardConfiguration,
      widgetConfigInitialValues
    ]
  )
}

export default useGetWebTippsConfigurationTabComponents
