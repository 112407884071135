import {
  Account,
  Box,
  Button,
  Col,
  Container,
  Row,
  Text
} from '@qonsoll/react-design'
import {
  StyledHeader,
  iconLanguageStyles,
  languageMenuStyles
} from './Header.styled'
import { avatarLetterFromNameOrEmail, createFullUserName } from 'helpers'

import { Icon } from '@qonsoll/icons'
import { Logo } from '../../../../../../components/BoilerplateLayout/components'
import MainMenu from '../MainMenu'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const Header = ({
  pageConfiguration,
  publicWizardConfiguration,
  initialValues,
  isWizard
}) => {
  const { t } = useTranslations()

  const user = useUser()
  const { firstName, lastName, email } = user
  const displayName = createFullUserName(firstName, lastName)
  const avatarFirstLetter = avatarLetterFromNameOrEmail(displayName, email)
  const {
    backgroundColor,
    textColor,
    avatarBackgroundColor: abg,
    avatarTextColor
  } = pageConfiguration?.header || {}
  return (
    <StyledHeader bg={backgroundColor} abg={abg}>
      <Container bounded>
        <Row v="center" noGutters px={4}>
          <Col cw="auto" mr="auto">
            <Logo
              isPreview
              logoUrl={pageConfiguration?.app?.logoUrl}
              initialValues={initialValues}
              additionalStyles={{ borderRadius: '10px' }}
            />
          </Col>
          <Col cw="auto" className="accountWrapper">
            {isWizard ? (
              <Col cw="auto" my={2}>
                <Box display="flex" alignItems="center" gap={30}>
                  <Box
                    {...languageMenuStyles}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <Icon {...iconLanguageStyles} fill={textColor} />
                    <Text color={textColor}>EN</Text>
                  </Box>
                  <Button
                    color={publicWizardConfiguration?.loginButtonColor}
                    backgroundColor={
                      publicWizardConfiguration?.loginButtonBackgroundColor
                    }>
                    {t('Login')}
                  </Button>
                </Box>
              </Col>
            ) : (
              <Account
                avatar={user?.avatarUrl}
                avatarText={
                  <Text
                    type="secondary"
                    color={avatarTextColor || 'white'}
                    isEllipsis>
                    {avatarFirstLetter}
                  </Text>
                }
                title={
                  <Text
                    type="secondary"
                    color={textColor || 'white'}
                    isEllipsis>
                    {displayName}
                  </Text>
                }
              />
            )}
          </Col>
        </Row>
      </Container>

      <MainMenu pageConfiguration={pageConfiguration} isWizard={isWizard} />
    </StyledHeader>
  )
}

Header.propTypes = {
  publicWizardConfiguration: PropTypes.object,
  pageConfiguration: PropTypes.object,
  initialValues: PropTypes.object,
  isWizard: PropTypes.bool
}

export default Header
