import { Header } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledHeader = styled(Header)`
  align-items: center;
  background-color: ${({ bg }) => bg || 'var(--ql-color-accent1)'};
  color: var(--ql-typography-text-color-primary-reverse);
  min-height: auto;
  flex-direction: column;
  padding: 0;

  // there is no another way of rewriting avatar background styles then way below
  .accountWrapper {
    span.ant-avatar.ant-avatar-lg.ant-avatar-circle {
      background-color: ${({ abg }) => abg || 'var(--ql-account-avatar-bg)'};
    }
  }
`

const iconLanguageStyles = {
  name: 'LanguageOutlined',
  size: 20,
  mr: 2
}

const languageMenuStyles = {
  cursor: 'pointer'
}

export { StyledHeader, iconLanguageStyles, languageMenuStyles }
